
                    @import "src/styles/_variables.scss";
                    @import "src/styles/_mixins.scss";
                
.wrapper {
    font-size: 16px;
    line-height: 24px;
    color: $textMain;
    max-width: 1200px;
    margin: auto;
    padding: 48px 0;
    position: relative;
    min-height: 100vh;
}

.title {
    font-weight: 400;
    font-size: 32px;
    line-height: 40px;
    color: $textMain;
}

.labelW100 {
    label {
        width: 100%;
    }
}

.nonActive {
    color: $red;
}

.titleContainer {
    display: flex;
    align-items: center;
    gap: 16px;
    margin-bottom: 32px;
}

.eventTopBlock {
    margin-bottom: 32px;
    display: flex;
    justify-content: space-between;
    padding: 24px;
    background: $white;
    box-shadow: 0px 0px 24px rgba(168, 168, 168, 0.12);
    border-radius: 8px;
}

.eventBottomBlock {
    justify-content: space-between;
    background: $white;
    box-shadow: 0px 0px 24px rgba(168, 168, 168, 0.12);
    border-radius: 8px;
}

.statusField {
    margin-left: 8px;
}

.statusSuccess {
    padding: 4px 8px;
    background: $yellowBg;
    border-radius: 8px;

    svg {
        path {
            stroke: $yellow;
        }
        circle {
            fill: $yellow;
        }
    }
}

.statusError {
    padding: 4px 8px;
    background: $redBg;
    border-radius: 8px;

    svg {
        path {
            stroke: $red;
        }
        circle {
            fill: $red;
        }
    }
}

.statusInfo {
    padding: 4px 8px;
    background: $grey;
    border-radius: 8px;

    svg {
        path {
            stroke: $border;
        }
        circle {
            stroke: $border;
        }
    }
}

.cancelReasonBlock {
    margin-top: 32px;
    margin-left: 64px;
    display: flex;
    align-items: center;

    svg {
        width: 16px !important;
        height: 16px !important;
        margin-right: 8px;
    }

    p {
        margin-right: 4px !important;
        font-weight: 400 !important;
        font-size: 12px !important;
        line-height: 16px !important;
        color: $textGrey;
    }
}

.eventField {
    display: flex;

    .typeIcon {
        padding: 7px 9px;
        margin-right: 8px;
        text-align: center;
        width: 40px;
        height: 40px;
        border-radius: 40px;

        svg {
            width: 24px;
            height: 24px;
        }
    }

    p {
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
    }

    .secondaryField {
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
    }
}

.infoField {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 24px;
    width: 100%;
    border-bottom: 1px solid $linesSeparator;

    div:first-child {
        margin-right: 20px;

        svg {
            width: 48px;
            height: 48px;
        }
    }

    p {
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        color: $textMain;
    }

    p:first-child {
        margin-bottom: 4px;
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
        color: $textLight;
    }
}

.secondaryField {
    font-weight: 400;
    font-size: 14px !important;
    line-height: 20px;
    color: $textGrey !important;
}

.checkButton {
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: $accentMain;
}

.circleGreen {
    width: 48px;
    height: 48px;
    background: #9bf5af;
    border-radius: 100px;
}

.circleAlert {
    width: 48px;
    height: 48px;
    border-radius: 100px;
}

.avatar {
    width: 48px;
    height: 48px;
    background: $blueLight;
    border-radius: 50%;
    overflow: hidden;

    svg {
        padding: 15px 17px 15px 17px;
    }

    img {
        width: 48px;
        height: 48px;
        object-fit: cover;
    }
}

.nameText {
    font-weight: 500 !important;
    font-size: 16px !important;
    line-height: 24px !important;
    color: $accentMain !important;
    cursor: pointer;
}
.nameProcedure {
    font-weight: 500 !important;
    font-size: 16px !important;
    line-height: 24px !important;
    color: $textMain !important;
}

.reasonBlock {
    justify-content: space-between;
    background: $white;
    box-shadow: 0px 0px 24px rgba(168, 168, 168, 0.12);
    border-radius: 8px;

    p {
        font-weight: 400 !important;
        font-size: 16px !important;
        line-height: 24px !important;
        color: $textMain !important;
    }
}

.reasonText {
    margin-top: 48px;
    margin-bottom: 16px;
}

.documentsBlock {
    display: flex;
    flex-direction: column;
    margin-bottom: 24px;
    gap: 8px;

    p {
        font-weight: 400 !important;
        font-size: 16px !important;
        line-height: 24px !important;
        color: $textMain !important;
    }

    .documentBlock {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        padding: 16px 24px 16px 16px;
        gap: 16px;
        background: $accentHighlight;
        border-radius: 8px;
        cursor: pointer;
        &:hover {
            background-color: darken($accentHighlight, 3%);
        }
    }
}

.documentLabel {
    margin-bottom: 4px;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
}

.chatBlock {
    justify-content: space-between;
    background: $white;
    box-shadow: 0px 0px 24px rgba(168, 168, 168, 0.12);
    border-radius: 8px;

    .chatWrapper {
        height: 70vh;
    }

    .chatHeader {
        position: relative;
        display: flex;
        justify-content: space-between;
        gap: 8px;
        padding: 16px;
        box-shadow: 0px 8px 8px rgba(27, 29, 33, 0.05);
    }

    .chatHeaderButton {
        padding: 6px 24px;
        background: $grey;
        border-radius: 4px;
        color: $textMain;
        height: auto;
        font-size: 14px;
        line-height: 20px;
        flex: 1 0 auto;

        &.active {
            background: $accentVeryLight;
        }
    }
}

.dateText {
    font-weight: 400 !important;
    font-size: 16px !important;
    line-height: 24px !important;
    color: $textMain !important;
}

.dateTextSmall {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: $textMain !important;
}

.managerLabel {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: $textGrey;
}

.managerName {
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: $textMain;
}

.nextButton {
    width: 32px;
    height: 32px;
    padding: 0;
    align-self: center;
}

.newPatient {
    padding: 4px 8px;
    background: $redBg;
    border-radius: 8px;

    p {
        margin: 0 !important;
        align-self: center;
        align-items: center !important;
        font-weight: 400 !important;
        font-size: 12px !important;
        line-height: 16px !important;
        color: $textMain !important;
    }
}

.resultsLink {
    margin-right: 16px;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: $accentMain;
}

.therapyText {
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: $textMain;
}

.editIcon {
    position: absolute;
    top: 0;
    right: 0;
    width: 24px !important;
    height: 24px !important;
    cursor: pointer;
}

.cancelReasonBlock {
    margin-top: 32px;
    margin-left: 64px;
    display: flex;
    align-items: center;

    svg {
        width: 16px !important;
        height: 16px !important;
        margin-right: 8px;
    }

    p {
        margin-right: 4px !important;
        font-weight: 400 !important;
        font-size: 12px !important;
        line-height: 16px !important;
        color: $textGrey;
    }
}

.reasonBlock {
    justify-content: space-between;
    background: $white;
    box-shadow: 0px 0px 24px rgba(168, 168, 168, 0.12);
    border-radius: 8px;

    p {
        font-weight: 400 !important;
        font-size: 16px !important;
        line-height: 24px !important;
        color: $textMain !important;
    }
}

.reasonText {
    margin-top: 48px;
    margin-bottom: 16px;
}

.changeReasonBlock {
    padding: 24px;
    background: $white;
    box-shadow: 0px 0px 24px rgba(168, 168, 168, 0.12);
    border-radius: 8px;

    .fieldLabel {
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
        color: $textGrey;
    }

    :global {
        .ant-form-item {
            margin-bottom: 0;
        }
    }

    .descriptionField {
        margin-top: 24px;
        margin-bottom: 48px;
    }
}

.complaintField {
    margin: 0;

    p {
        display: inline-block;
        margin-left: 16px;
    }
}

.courseName {
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    text-align: left;
}

.deliveryAddress {
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    text-align: left;
}

.addressDefinition, .extendedStatus {
    font-size: 14px;
    font-weight: 400;
    text-align: left;
    color: #505862;
}

.failIcon {
    max-width: 16px;
    max-height: 16px;
}

.progressIcon {
    max-width: 20px;
    max-height: 20px;
    stroke: #FF8A43;
}

.waitCheckDisplayInfo {
    display: flex;
    align-items: center;
    justify-content: start;
    column-gap: 4px;
    font-size: 14px;
    font-weight: 500;
    text-align: left;
    color: #FF8A43;
}

.blackColor {
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    text-align: left;
    color: $textMain;
}

.addressNotSpecified, .noCheck {
    display: flex;
    align-items: center;
    justify-content: start;
    column-gap: 4px;
    font-size: 14px;
    font-weight: 500;
    text-align: left;
    color: #F64D4D;
}

.notice {
    height: 32px;
    display: flex;
    align-items: center;
    background-color: $lightRedBg;
    border-radius: 8px;
    padding: 8px 8px;
    font-size: 14px;
    line-height: 20px;
    color: $textMain;
    margin-top: 8px;
    margin-left: -6px;
    column-gap: 8px;
}


                    @import "src/styles/_variables.scss";
                    @import "src/styles/_mixins.scss";
                
.statusField {
    max-width: 130px;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;

    div {
        max-width: fit-content;
    }

    .baseStatus {
        padding: 4px 8px;
        border-radius: 8px;
    }

    .created {
        @extend .baseStatus;
        color: $textLight;
        background-color: $grey;
    }

    .pending {
        @extend .baseStatus;
        color: $textMain;
        background-color: $yellowBg;
    }

    .confirmed {
        @extend .baseStatus;
        color: $textMain;
        background-color: $greenLight;
    }

    .completed {
        @extend .baseStatus;
        color: $textMain;
        background-color: $linesSeparator;
    }

    .cancelled {
        @extend .baseStatus;
        color: $textMain;
        background-color: $redBg;
    }

    .paymentFailed {
        @extend .baseStatus;
        color: $textMain;
        background-color: $redBg;
    }
}


                    @import "src/styles/_variables.scss";
                    @import "src/styles/_mixins.scss";
                
.wrapper {
    padding: 8px;
    color: $textMain;
    :global {
        .ant-collapse {
            .ant-collapse-item {
                .ant-collapse-header {
                    padding: 0;
                }
            }
        }
        .ant-collapse-borderless .ant-collapse-item .ant-collapse-content .ant-collapse-content-box {
            padding: 0;
        }
    }

    ul {
        list-style-type: disc;
    }

    li {
        list-style-type: disc;
        font-size: 14px;
        line-height: 20px;
    }
}

.formTitle {
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: $textMain;
}

.paragraph {
    margin-bottom: 10px;
    color: $textMain;
    margin-bottom: 20px;
    p {
        font-size: 14px;
        line-height: 20px;
    }

    li {
        list-style-type: disc;
        font-size: 14px;
        line-height: 20px;
    }

    .title {
        font-weight: 500;
    }
}

.active {
    transform: rotate(180deg);
    transition: all 0.4s;
}

.nonActive {
    transform: rotate(0);
    transition: all 0.4s;
}

.collapse {
    background: $white;
}

.duration {
    padding: 4px 16px;
    background: #d2f6ff;
    border-radius: 20px;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: $textMain;
    margin-bottom: 8px;
}

.warning {
    display: flex;
    align-items: center;
    gap: 8px;
    font-weight: 700;

    .textBlock {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        gap: 4px;
        font-size: 12px;
        line-height: 16px;

        .warn {
            color: $red;
        }

        .info {
            color: $textGrey;
        }

        .main {
            color: $textMain;
        }
    }
}

.bubble {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: $bgLightGrey;
    padding: 8px 8px 8px 16px;
    border-radius: 8px;
    cursor: pointer;

    .subtitle {
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        color: $textGrey;
        font-weight: 400;
    }
}

.comment-icon-space {
    margin-left: 11px;

    .commentTitle {
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
        color: $textMain;
    }

    .commentSubtitle {
        font-weight: 400;
        font-size: 12px;
        line-height: 16px;
        color: $textGrey;
    }
}

.title {
    font-weight: 400;
    font-size: 24px;
    line-height: 28px;
    color: $textMain;
}

.subtitle {
    font-size: 14px;
    line-height: 20px;
    font-weight: 500;
    color: $textLight;
}

.touchable {
    cursor: pointer;
}

.relationship {
    margin-bottom: 20px;
}

.edit {
    height: 300px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.badge {
    position: absolute;
    top: 5px;
    right: 0;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background-color: $redDark;
}
